<template>
    <div class="mb-1">
        <b-row>
            <b-col lg="4" v-for="(messages, index) in all_messages" :key="index">
                <b-card-code :title="index">
                    <!-- badge -->
                    <b-list-group>
                        <b-list-group-item v-for="(scores, score_index) in messages" :key="score_index" class="d-flex justify-content-between align-items-center">
                            <span class="text-capitalize">{{ scores.score_meaning }}</span>
                            <div class="d-flex">
                                <span>
                                    <b-badge variant="light-primary" @click="showUpdateModal(scores)" v-b-tooltip.hover.top="'Edit message'" class="cursor-pointer">
                                        <feather-icon icon="EditIcon" />
                                    </b-badge>
                                </span>
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                </b-card-code>
            </b-col>
        </b-row>

        <add-questionnaire-message />
        <edit-questionnaire-message />
    </div>
</template>

<script>
import EventBus from "../../../main.js";
import { BRow, BCol, BListGroup, BListGroupItem, BBadge, BCardText, VBTooltip, BButton } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddQuestionnaireMessage from "../../../components/modals/questionnaire/messages/AddQuestionnaireMessage.vue"
import EditQuestionnaireMessage from "../../../components/modals/questionnaire/messages/EditQuestionnaireMessage.vue"

export default {
    components: {
        BRow,
        BCol,
        BCardCode,
        BBadge,
        BListGroup,
        BCardText,
        BListGroupItem,
        BButton,
        AddQuestionnaireMessage,
        EditQuestionnaireMessage
    },
    data() { 
        return {
            questionnaire_id: this.$route.params.id,
            all_messages: {},
        }
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    methods: {
        getAllQuestionnaireMessages() {
            this.$http.get("/api/auth/admin/questionnaire/"+this.questionnaire_id+"/messages/grouped")
                .then((res) => {
                    if(res != undefined) {
                        this.all_messages = res.data
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Something went wrong, please login again",
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                })
        },

        removeMessage(message_id) {
            this.$bvModal
                .msgBoxConfirm('The selected message will be deleted!', {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.delete("/api/auth/admin/questionnaire/messages/" + message_id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The message was successfully deleted!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });
                        
                        // Refresh messages list
                        this.getAllQuestionnaireMessages();                 
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error deleting the message!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        },

        showCreateModal(type) {
            EventBus.$emit('questionnaireMessageCreate', type)
        },

        showUpdateModal(data) {
            EventBus.$emit('questionnaireMessageUpdate', data)
        },
    },
    mounted() {
        this.getAllQuestionnaireMessages()

        EventBus.$on('getFreshQuestionnaireMessages', () => {
            this.getAllQuestionnaireMessages()
        })
    }
}
</script>

<template>
  <b-modal ref="add-questionnaire-messages-modal" hide-footer centered size="lg">
    <div class="d-block py-2 px-1">
        <div class="mb-2">
            <h4 class="mb-0">Create message</h4>
            <small>Choose score and add message</small>
        </div>
        <b-row class="pb-2">
            <b-col>
                <validation-observer ref="addValidation">
                    <validation-provider
                        #default="{ errors }"
                        name="Message"
                        rules="required"
                    >
                        <div>
                            <label>Message</label>
                            <quill-editor v-model="text" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </div>
                    </validation-provider>
                    
                    <validation-provider
                        #default="{ errors }"
                        name="Score meaning"
                        rules="required"
                    >
                        <div class="mt-3">
                            <label>Score meaning</label>
                            <b-form-select
                              v-model="selected_empty_message"
                              :options="empty_messages"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </div>
                    </validation-provider>
                </validation-observer>
            </b-col>
        </b-row>

        <b-row class="pb-2">
            <b-col>
                <b-button variant="primary" class="float-right" @click="createMessage">Create</b-button>
            </b-col>
        </b-row>

    </div>
  </b-modal>
</template>

<script>
import EventBus from "../../../../main.js";
import { BModal, BForm, BRow, BCol, BButton, BFormSelect } from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
    components: {
        BModal,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormSelect,
        quillEditor,
        ValidationProvider,
        ValidationObserver,
        required
    },
    data() {
        return {
            questionnaire_id: this.$route.params.id,
            message_id: "",
            text: "",
            empty_messages: [],
            selected_empty_message: "",
            type: {}
        };
    },
    methods: {
        getEmptyMessages() {
          this.$http.get("/api/auth/admin/questionnaire/messages/availability/" + this.questionnaire_id)
              .then((res) => {
                  if (res != undefined) {
                      let messages = []

                      for(let i=0; i<res.data[this.type].length; i++) {
                        let uppercase_text = res.data[this.type][i].charAt(0).toUpperCase() + res.data[this.type][i].slice(1)
                        messages.push({ text: uppercase_text, value:res.data[this.type][i] })
                      }

                      this.empty_messages = messages
                      this.selected_empty_message = this.empty_messages[0].value
                  }
              })
              .catch((err) => {
                  console.log(err);
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                      title: "Something went wrong, please login again",
                      icon: "AlertCircleIcon",
                      variant: "danger",
                      },
                  });
              });
        },
        createMessage() {
            this.$refs.addValidation.validate().then((success) => {
                if (success) {

                  let data = {
                    score_meaning: this.selected_empty_message,
                    questionnaire_id: this.questionnaire_id,
                    text: this.text,
                    type: this.type
                  }

                  // Request to create toolkit
                  this.$http.post("/api/auth/admin/questionnaire/messages", data)
                    .then((res) => {
                        if (res != undefined) {
                            EventBus.$emit("getFreshQuestionnaireMessages");

                            // Close modal
                            this.$refs["add-questionnaire-messages-modal"].hide();

                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Questionnaire score message updated successfully",
                                    icon: "CheckIcon",
                                    variant: "success",
                                },
                            });

                            this.resetFields()
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: "Something went wrong, please login again",
                              icon: "AlertCircleIcon",
                              variant: "danger",
                            },
                        });
                    });
                }
            });
        },

        resetFields() {
          this.text = ""
        }
    },
    mounted() {
        EventBus.$on("questionnaireMessageCreate", (type) => {
            this.type = type

            this.getEmptyMessages()

            // Reset fields
            this.resetFields()

            if (this.$refs["add-questionnaire-messages-modal"] != undefined) {
                this.$refs["add-questionnaire-messages-modal"].show();
            }
        });
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";

.ql-container {
    max-height: 400px;
    overflow-y: auto;
}
</style>
<template>
  <b-modal ref="edit-questionnaire-messages-modal" hide-footer centered size="lg">
    <div class="d-block py-2 px-1">
        <div class="mb-2">
            <h4 class="mb-0">Update message</h4>
            <small>for <span class="text-capitalize">{{score_meaning}}</span> score</small>
        </div>
        <b-row class="pb-2">
            <b-col>
                <validation-observer ref="editValidation">
                    <validation-provider
                        #default="{ errors }"
                        name="message"
                        rules="required"
                    >
                        <div>
                            <label>Message</label>
                            <quill-editor v-model="text" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </div>
                    </validation-provider>
                </validation-observer>
            </b-col>
        </b-row>

        <b-row class="pb-2">
            <b-col>
                <b-button variant="primary" class="float-right" @click="updateMessage">Update</b-button>
            </b-col>
        </b-row>

    </div>
  </b-modal>
</template>

<script>
import EventBus from "../../../../main.js";
import { BModal, BForm, BRow, BCol, BButton } from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
    components: {
        BModal,
        BForm,
        BRow,
        BCol,
        BButton,
        quillEditor,
        ValidationProvider,
        ValidationObserver,
        required
    },
    data() {
        return {
            message_id: "",
            text: "",
            score_meaning: ""
        };
    },
    methods: {
        updateMessage() {
            this.$refs.editValidation.validate().then((success) => {
                if (success) {
                let data = {
                    text: this.text
                }

                // Request to create toolkit
                this.$http.patch("/api/auth/admin/questionnaire/messages/" + this.message_id, data)
                    .then((res) => {
                        if (res != undefined) {
                            EventBus.$emit("getFreshQuestionnaireMessages");

                            // Close modal
                            this.$refs["edit-questionnaire-messages-modal"].hide();

                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Worksheet edited successfully",
                                    icon: "CheckIcon",
                                    variant: "success",
                                },
                            });

                            this.resetFields()
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                            title: "Something went wrong, please login again",
                            icon: "AlertCircleIcon",
                            variant: "danger",
                            },
                        });
                    });
                }
            });
        },

        resetFields() {
            this.message_id = ""
            this.text = ""
            this.score_meaning = ""
        }
    },
    mounted() {
        EventBus.$on("questionnaireMessageUpdate", (data) => {

            // Reset fields
            this.resetFields()

            this.text = data.text
            this.message_id = data.id
            this.score_meaning = data.score_meaning

            if (this.$refs["edit-questionnaire-messages-modal"] != undefined) {
                this.$refs["edit-questionnaire-messages-modal"].show();
            }
        });
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";

.ql-container {
    max-height: 400px;
    overflow-y: auto;
}
</style>